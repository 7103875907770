import * as React from 'react'

interface GoogleDriveProps {
  data: any
}

class GoogleDrivePage extends React.Component<GoogleDriveProps> {
  render() {
    return (
      <div>google drive</div>
    )
  }
}

export default GoogleDrivePage
